<template>
    <div class="header">
        <div v-if="hasGeneratePermission" class="generate">
            <div class="generate-filter">
                <Dropdown
                    v-for="(option, key) in dropdownSetting"
                    :key="key"
                    :name="key"
                    :title="option.title"
                    :options="options[key]"
                    v-model="payload[key]"
                />
            </div>
            <div class="generate-update">
                <p v-if="isGenerating" class="reminder">
                    有獎項報表正在生產中，請稍候更新報表狀態
                </p>
                <Button
                    :disabled="isGenerating"
                    buttonWord="生產報表"
                    buttonStyle="blue-hollow"
                    iconClass="generate-excel-icon"
                    @click="generateReport"
                />
            </div>
        </div>
        <div class="header-info">
            <div class="describe">最後更新時間：{{ updatedAt }}</div>
            <Button
                @click="getAwardsInfo"
                class="header-info-button"
                buttonWord="更新報表狀態"
                buttonStyle="blue"
            />
        </div>
    </div>
    <div class="table">
        <Table
            :column-config="tableColumnConfig"
            :rows="sortedReport"
            :isLoading="isUpdating"
        >
            <template v-slot:reportLink="{ isHeader, row }">
                <div
                    v-if="!isHeader"
                    :class="['download-content-icon', { disabled: row.status }]"
                    @click="downloadReport(row)"
                />
            </template>
            <template v-slot:status="{ isHeader, row }">
                <div v-if="!isHeader" :class="{ generating: row.status }">
                    {{ row.status || '-' }}
                </div>
            </template>
        </Table>
    </div>
    <DownloadModal
        title="業績報表下載"
        modal-size="615px"
        v-model:action="action"
    />
</template>
<script>
import Dropdown from '@/components/Dropdown.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import {
    getAwardsCodesAPI,
    getBackstageAwardsReportInfosAPI,
    postBackstageGenerateAwardsReportAPI,
    getBackstageDownloadAwardsReportAPI
} from '@/assets/javascripts/api.js'
import _ from 'lodash'
import { awardsDownloadColumn } from '@/assets/javascripts/backstage/setting.js'
import DownloadModal from '@/containers/backstage/DownloadModal.vue'
import dayjs from 'dayjs'

export default {
    name: 'AwardDownload',
    components: {
        Dropdown,
        Button,
        Table,
        DownloadModal
    },
    methods: {
        async getAwardsInfo() {
            this.$showLoading()
            this.isUpdating = true
            try {
                const res = await getBackstageAwardsReportInfosAPI()
                this.updatedAt = dayjs().format('YYYY.MM.DD HH:mm:ss')
                this.reportList = res.data.excelInfos
            } catch (e) {
                this.action = 'updated-failure'
            } finally {
                this.isUpdating = false
                this.$hideLoading()
            }
        },
        async getAwardsOptions() {
            this.$showLoading()
            try {
                const res = await getAwardsCodesAPI()
                this.awardCodes = res.data.awards
            } catch (e) {
                console.log(e)
            } finally {
                this.$hideLoading()
            }
        },
        async generateReport() {
            if (this.isGenerating) return
            this.$showLoading()
            try {
                await postBackstageGenerateAwardsReportAPI(this.payload)
                await new Promise((resolve) => {
                    setTimeout(() => {
                        this.getAwardsInfo()
                        resolve()
                    }, 3000)
                })
                this.action = 'success'
            } catch (e) {
                this.action = 'generate-failure'
            } finally {
                this.$hideLoading()
            }
        },
        async downloadReport(row) {
            if (row.status) return
            try {
                const res = await getBackstageDownloadAwardsReportAPI(row.id)
                if (!res.data?.urls?.length) throw new Error()
                const a = document.createElement('a')
                a.href = res.data.urls[0]
                a.setAttribute('target', '_blank')
                a.click()
            } catch (e) {
                this.action = 'download-failure'
            }
        }
    },
    computed: {
        options() {
            const yearOptions = []
            for (let i = this.minYear; i <= new Date().getFullYear(); i++)
                yearOptions.push({ label: i, value: i })
            return {
                groupId: this.awardCodes.map((award) => {
                    return { label: award.name, value: award.groupId }
                }),
                year: yearOptions
            }
        },
        sortedReport() {
            const data = _.cloneDeep(this.reportList)
            return data.sort((a, b) => b.id - a.id)
        },
        isGenerating() {
            return this.reportList.some((report) => report.status)
        },
        hasGeneratePermission() {
            const alias = 'BackstageReportAwards'
            return this.$store.state.permissions[alias]?.includes('generate')
        }
    },
    data() {
        return {
            dropdownSetting: {
                groupId: {
                    title: '競賽名稱'
                },
                year: {
                    title: '獎項年度'
                }
            },
            awardCodes: [],
            minYear: 2023,
            payload: {
                groupId: null,
                year: null
            },
            isUpdating: false,
            updatedAt: '',
            reportList: [],
            tableColumnConfig: awardsDownloadColumn,
            action: null
        }
    },
    async mounted() {
        await this.getAwardsInfo()
        await this.getAwardsOptions()
        this.payload.year = new Date().getFullYear()
        this.payload.groupId = this.awardCodes[0].groupId
    }
}
</script>
<style lang="scss" scoped>
:deep(.default-button) {
    max-width: 160px;
    width: 124px;
    @media screen and (max-width: 576px) {
        width: auto;
        max-width: none;
    }
}
:deep(.status) {
    .generating {
        color: $fourth-blue;
    }
}
:deep(.report-link) {
    .download-content-icon {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }
}
:deep(.status) {
    .generating {
        color: $fourth-blue;
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px 0;
        margin-bottom: 0;
    }
    .generate {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 100%;

        border-bottom: 1px solid #d9d9d9d9;
        margin-bottom: 15px;
        .generate-filter {
            display: grid;
            grid-template-columns: repeat(auto-fill, 210px);
            justify-content: space-between;
            gap: 0 20px;
            width: 100%;

            @media screen and (max-width: 576px) {
                grid-template-columns: 100%;
                :deep(.input-container) {
                    width: 100%;
                }
            }
        }
        .generate-update {
            display: flex;
            align-self: flex-end;
            gap: 10px;
            margin-bottom: 15px; //與border 的距離
            @media screen and (max-width: 576px) {
                width: 100%;
                justify-content: flex-start;
                flex-direction: column;
            }
            .reminder {
                margin: 0; //remove p-tag default
                color: $fourth-red;
                font-size: 12px;
                align-self: flex-end;
                @media screen and (max-width: 576px) {
                    align-self: flex-start;
                    order: 1;
                }
            }
        }
    }
    .header-info {
        display: flex;
        align-items: flex-end;
        margin-left: auto;
        gap: 10px;
        .describe {
            font-size: 12px;
            @media screen and (max-width: 576px) {
                padding: 0;
            }
        }
    }
}
</style>
